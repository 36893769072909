





























import Vue from "vue";
import { mapState } from "vuex";
import { Toast } from "vant";
import { dispatch, Oauth2 } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("app", {
      isInWeChat: "isInWeChat",
      isMobile: "isMobile",
    }),
    redirect(): string {
      if (this.$route.query.redirect) {
        return this.$route.query.redirect + "";
      }
      return this.$paths.home;
    },
  },
  methods: {
    onOauth2SignIn(channel: "wechat" | "qq") {
      let oauth2: Oauth2;
      if (channel === "wechat") {
        if (this.isInWeChat) {
          oauth2 = {
            channel: "wechat",
            action: "callback",
            scope: "userinfo",
          };
        } else if (this.isMobile) {
          Toast("请在微信中打开");
          return;
        } else {
          oauth2 = { channel, action: "callback" };
        }
      } else {
        oauth2 = { channel, action: "callback" };
      }
      dispatch.appGetOauth2Url(oauth2).catch(() => {
        Toast("登录失败，请稍后重试");
        setTimeout(() => {
          window.location.href =
            this.$paths.authSignIn +
            "?redirect=" +
            encodeURIComponent(this.redirect);
        }, 1500);
      });
    },
    checkSignIn() {
      dispatch
        .appOauth2SignIn()
        .then((res) => {
          if (res) {
            window.location.href = this.redirect;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("登录失败，请稍后重试");
          setTimeout(() => {
            console.log(encodeURIComponent(this.redirect));
            console.log(this.redirect);

            // window.location.href =
            //   this.$paths.authSignIn +
            //   "?redirect=" +
            //   encodeURIComponent(this.redirect);
          }, 1500);
        });
    },
  },
  mounted() {
    // this.checkSignIn();
  },
});
